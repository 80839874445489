<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <b-card class="mt-1" title="INFORMATIONS BANCAIRES">
          <div class="row px-2 border-top">
            <b-card
              class="mb-0 block-text block-text-media"
              style="margin-right: 14%"
            >
              <div class="row">
                <b-icon-info-circle
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-info-circle>
                <b-card-text>
                  <span style="color: #b4b7bd">SOLDE DISPONIBLE</span> <br />
                  <div v-if="this.operationsNumber == 0" class="spinner-border" role="status">
                  </div>
                  {{ $thousandSeparator(calculerSolde) }}
                  {{ firstCurrency.name }}
                </b-card-text>
              </div>
            </b-card>
            <b-card
              class="mb-0 block-text block-text-media"
              style="margin-right: 14%"
            >
              <div class="row">
                <b-icon-arrow-left-circle
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-arrow-left-circle>
                <b-card-text>
                  <span style="color: #b4b7bd">PRÊT</span> <br />
                  <div v-if="this.operationsNumber == 0" class="spinner-border" role="status">
                  </div>
                  {{ $thousandSeparator(soldePret) }} {{ firstCurrency.name }}
                </b-card-text>
              </div>
            </b-card>
            <!-- style="margin-right: 14%" -->
            <b-card
              class="mb-0 block-text block-text-media"
            >
              <div class="row">
                <b-icon-compass
                  class="h3 text-primary"
                  style="margin-top: 5%; margin-right: 10px"
                ></b-icon-compass>
                <b-card-text>
                  <span style="color: #b4b7bd">SOLDE RÉEL</span> <br />
                  <div v-if="this.operationsNumber == 0" class="spinner-border" role="status">
                  </div>
                  {{ $thousandSeparator(calculerSolde + soldePret) }}
                  {{ firstCurrency.name }}
                </b-card-text>
              </div>
            </b-card>
          </div>
        </b-card>

        <div class="demo-inline-spacing">
          <b-button size="sm" variant="outline-primary" @click="showVirement">
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            VIREMENT
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="showReport('print')"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            VOIR LE POINT
          </b-button>
          <b-button
            size="sm"
            @click="ExportFomatExcel"
            variant="outline-primary"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            EXPORTER EN TABLEUR
          </b-button>

          <b-button
            size="sm"
            @click="ExportAllToFomatExcel"
            variant="outline-primary"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            TOUT EXPORTER EN TABLEUR
          </b-button>


          <b-button
            size="sm"
            class="float-right ml-auto"
            variant="outline-primary"
            @click="compteModal"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            AJOUTER UN COMPTE
          </b-button>
        </div>

        <div class="row mt-1">
          <div class="col-lg-8">
            <b-card-code>
              <b-container fluid>
                <div class="row">
                  <div class="custom-search">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <b-form-input
                            placeholder="Enregistrer du"
                            type="date"
                            class="d-inline-block"
                            @input="startDateFilterFunction"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <b-form-input
                            @input="endDateFilterFunction"
                            placeholder="Au"
                            type="date"
                            class="d-inline-block"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <b-form-select
                            v-model="comptesFilterValue"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="comptes"
                            value-field="libelle"
                            text-field="libelle"
                            @input="compteConcerneFilterFunction"
                            push-tags
                            placeholder="Compte concerné"
                          >
                            <template #first>
                              <b-form-select-option
                                :value="null"
                                style="color: #b4b7bd; text-align: center"
                              >
                                Compte concerné
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>

                    <b-col>
                      <div class="col-lg-3 col-md-6 ml-0">
                          <b-form-select
                                      ref="status_filter"
                                      v-model="selectedTrie"
                                      :options="trieDate"
                                      @change="changeTrie"
                                      @input="filterValue"
                                  >
                          </b-form-select>
                          <label>Trier Par Date</label>
                      </div>
                    </b-col>

                    </b-row>
                  </div>
                  <b-table
                    responsive
                    :fields="headers"
                    :items="operations"
                    size="lg"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    class="border-bottom"
                    @filtered="onFiltered"
                    :filter="filter"
                    :filter-function="filterFunction"
                  >
                    <template #cell(entree)="data">
                      {{
                        data.item.entree
                          ? $thousandSeparator(data.item.entree)
                          : "-----"
                      }}
                    </template>
                    <template #cell(sortie)="data">
                      {{
                        data.item.sortie
                          ? $thousandSeparator(data.item.sortie)
                          : "-----"
                      }}
                    </template>
                    <template #cell(solde)="data">
                      {{ $thousandSeparator(data.item.solde) }}
                    </template>
                  </b-table>
                  <div class="my-2 mx-2 row">
                    <div class="col">
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        style="width: 70px"
                      ></b-form-select>
                      <span class="text-nowrap ml-1" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
                        <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                        </span>
                      <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
                    </div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      aria-controls="my-table"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </b-container>
            </b-card-code>
          </div>
          <div class="col-lg-4">
            <b-card-code>
              <b-container fluid>
                <div class="row">
                  <div class="custom-search">
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-input
                            placeholder="Rechercher"
                            type="text"
                            class="d-inline-block"
                            v-model="filterCompte"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <b-table
                    responsive
                    :fields="headersComptes"
                    :items="comptes"
                    :filter="filterCompte"
                    size="lg"
                    v-model="ExcelBanque"
                  >
                    <template #cell(solde)="data">
                      {{ $thousandSeparator(data.item.solde) }}
                    </template>
                  </b-table>
                  <div class="my-2 mx-2 row">
                    <div class="col">
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageCompte"
                        :options="pageOptionsCompte"
                        style="width: 70px"
                      ></b-form-select>
                    <span class="text-nowrap ml-1" v-if="totalRowsCompte != 0"> 1 - <span v-if="perPage >= totalRowsCompte">{{ totalRowsCompte }}</span> 
                      <span v-if="perPage < totalRowsCompte"> {{perPage}} </span> sur {{ totalRowsCompte }}
                      </span>
                    <span class="text-nowrap ml-2" v-if="totalRowsCompte == 0"> 0 éléments</span>
                    </div>
                    <b-pagination
                      v-model="currentPageCompte"
                      :total-rows="totalRowsCompte"
                      :per-page="perPageCompte"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      aria-controls="my-table"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </b-container>
            </b-card-code>
          </div>
        </div>

        <!-- modal add associe principal start  -->
        <b-modal
          v-model="modalAddCompte"
          title="AJOUTER UN COMPTE"
          size="sg"
          @hidden="resetModal"
          hide-footer
          centered
        >
          <!-- form start -->
          <validation-observer ref="addCompteForm">
            <b-form @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col class="col-6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Libellé"
                      rules="required"
                    >
                      <label for="">Nom du compte</label>
                      <b-form-input
                        id="libelle"
                        v-model="addCompteFormulaire.libelle"
                        placeholder="Nom du compte"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{
                        errors[0] ? "" : errorsLibelleModal
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col class="col-6">
                  <b-form-group>
                    <!-- <b-form-input
									v-model="addCompteFormulaire.solde"
									:disabled="amountsEqual"
									placeholder="Solde initial"
									:hint="'Maximum : ' + $thousandSeparator(maxSolde)"
								/> -->
                    <label for="">Solde initial</label>
                    <cleave
                      class="form-control"
                      :raw="false"
                      type="text"
                      :disabled="amountsEqual"
                      :options="numberFormat"
                      v-model="addCompteFormulaire.solde"
                      placeholder="0"
                      :hint="'Maximum : ' + $thousandSeparator(maxSolde)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group class="mb-0">
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2 mr-1"
                  @click="modalAddCompte = false"
                >
                  Annuler
                </b-button>

                <b-button
                  v-if="etatButton"
                  variant="primary"
                  class="mt-2"
                  @click="addCompte"
                >
                  Enregistrer
                </b-button>

                <b-button v-else variant="primary" class="mt-2">
                  <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                  ></b-icon-arrow-clockwise>
                  En cours
                </b-button>
              </b-form-group>
            </b-form>
          </validation-observer>
          <!-- form end -->
        </b-modal>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="etatAdd">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BIconFileEarmarkArrowDownFill,
  BIconCheckCircleFill,
  BButton,
  BIconCircleFill,
  BIconArrowClockwise,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BIconInfoCircle,
  BContainer,
  BIconCompass,
  BIconArrowLeftCircle,
  BCard,
  BIconPlus,
  BCardText,
  BTable,
  BFormSelectOption,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
//import { CurrencyInput, parse } from 'vue-currency-input'

export default {
  components: {
    //CurrencyInput,
    BCardCode,
    BCard,
    Cleave,
    BIconCheckCircleFill,
    BIconFileEarmarkArrowDownFill,
    BCardText,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BIconArrowClockwise,
    BIconCompass,
    BIconArrowLeftCircle,
    BIconInfoCircle,
    BRow,
    BIconPlus,
    BIconCircleFill,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BFormSelectOption,
  },
  data() {
    return {
      numberFormat: {
        delimiter: " ",
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      hidden: true,
      etatButton: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      required,
      perPage: 5,
      operationsNumber: 0,
      currentPage: 1,
      allRows: 0,
      filter: null,
      pageOptions: [],
      perPageCompte: 5,
      currentPageCompte: 1,
      filter: null,
      pageOptionsCompte: [5, 10, 25, 50, 100],
      dir: false,
      accountsList: [],
      comptesReglement: [],
      comptesFsr: [],
      fsrImportateurList: [],
      operations: [],
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Decroissant", value: "decroissant" },
      ],
      selectedTrie: null,
      operationsBackup: [],
      societes: [],
      fournisseurs: [],
      comptes: [],
      importateurs: [],
      devises: [],
      concernedAccounts: [],
      headersComptes: [
        { label: "Libellé", key: "libelle" },
        { label: "Solde", key: "solde" },
      ],
      headers: [
        {
          label: "Date de l'opération",
          align: "left",
          sortable: false,
          key: "created_at",
        },
        { label: "Description", key: "libelle" },
        { label: "Compte concerné", key: "compte.libelle" },
        { label: "Entrée", key: "entree" },
        { label: "Sortie", key: "sortie" },
        { label: "Solde", key: "solde" },
      ],
      soldePret: 0,
      comptesFilterValue: null,
      filterCompte: null,
      compteConcerneFilterValue: null,
      startDateFilterValue: null,
      endDateFilterValue: null,
      modalAddCompte: false,
      errorsLibelle: [],
      addCompteFormulaire: {},
      firstCurrency: {},
      errorsLibelleModal: "",
      ExcelBanque: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    // OPTIONS POUR LE FORMATAGE DES MONTANTS
    /* options() {
      return {
        distractionFree: false,
        valueAsInteger: true,
        AllowNegative: false,
        autoDecimalMode: true,
        currency: null,
        precision: 0,
      }
    }, */
    
    maxSolde() {
      let accountSum = 0;
      this.comptes.forEach((element) => {
        accountSum += element.solde;
      });

      return this.calculerSolde - accountSum;
    },

    totalRowsCompte() {
      return this.comptes.length;
    },

    totalRows() {
      
      this.pageOptions = [5, 10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    // SOLDE DU COMPTE
    calculerSolde() {
      let entree = 0;
      let sortie = 0;
      this.operations.forEach((element) => {
        if (element.entree) {
          entree += element.entree;
        }

        if (element.sortie) {
          sortie += element.sortie;
        }
      });

      return entree - sortie;
    },
    //CALCUL DU SOLDE DU COMPTE DANS LES FILRES
    soldeCompte() {
      let entree = 0;
      let sortie = 0;
      let operations = this.operations;

      if (this.comptesFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte.libelle == this.comptesFilterValue
        );
      }

      /*
        - Déterminer le premier solde  : il s'agit du montant de la premiere opération (entree ou sortie)
        -
      */
      if (operations.length > 0) {
        operations
          .slice()
          .reverse()
          .forEach((element, index) => {
            if (index == 0) {
              element.solde = element.entree ? element.entree : -element.sortie;
            } else if (index > 0) {
              if (element.entree) {
                element.solde =
                  operations[operations.length - index].solde + element.entree;
              } else if (element.sortie) {
                element.solde =
                  operations[operations.length - index].solde - element.sortie;
              } else {
                element.solde = operations[operations.length - index].solde + 0;
              }
            }
          });
      }
      operations.forEach((element, index) => {
        if (element.entree) {
          entree += element.entree;
        }

        if (element.sortie) {
          sortie += element.sortie;
        }
      });
      return this.$thousandSeparator(entree - sortie);
    },
    amountsEqual() {
      let accountSum = 0;

      this.comptes.forEach((element) => {
        accountSum += element.solde;
      });

      if (this.calculerSolde == accountSum) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getDevisesList();
    this.getComptes();
    this.caisseData();
    this.comptesData();
    this.selectedTrie = "decroissant"
  },
  methods: {
    etatAdd() {
      //   this.add = true;
      //   this.hiddenEmpty = true;
      this.compteModal();
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },


    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },



    getDevisesList() {
      this.$http.get("/devises").then((response) => {
        this.devises = response.data.data;
        this.firstCurrency = response.data.data.find(
          ({ devise_principale }) => devise_principale === 1
        );
      });
    },
    startDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.startDateFilterValue = val;
    },

    endDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.endDateFilterValue = val;
    },

    compteConcerneFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.compteConcerneFilterValue = val;
    },
    filterFunction(row, DateFilter) {
      var start = null;
      var end = null;
      if (this.startDateFilterValue) {
        var parts = this.startDateFilterValue.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.endDateFilterValue) {
        var parts = this.endDateFilterValue.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      var value = new Date(this.toDate(row.created_at));
      if (
        this.compteConcerneFilterValue == "" ||
        this.compteConcerneFilterValue == null
      ) {
        if (!start && !end) {
          return true;
        } else if (start && !end) {
          return value >= start;
        } else if (!start && end) {
          return value <= end;
        }
        return value >= start && value <= end;
      } else {
        if (row.compte.libelle == this.compteConcerneFilterValue) {
          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return value >= start;
          } else if (!start && end) {
            return value <= end;
          }
          return value >= start && value <= end;
        } else {
          return false;
        }
      }
    },
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },

    changeTrie() {
          if(this.selectedTrie == "croissant") {
            this.operations = this.operations.reverse();
          }
          if(this.selectedTrie == "decroissant") {
          this.operations = this.operations.reverse();
          }
      },

    getComptes() {
      this.$http.get(`/caisse-principale-resources`).then((response) => {
        if (response.status) {
          this.hiddenLoader = true;
        }

        this.comptes = response.data.data;

        if (this.comptes.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    async comptesData() {
      await this.$http.get("/comptes").then((response) => {
        let avanceP = response.data.data.find(
          ({ libelle }) => libelle === "Prêt Employé/Partenaire"
        );
        if (avanceP) this.soldePret = avanceP.solde;

        response.data.data.forEach((element) => {
          if (
            element.libelle != "Prêt Employé/Partenaire" &&
            element.libelle != "Retrait Associés" &&
            element.libelle != "Carte Importateur" &&
            element.libelle != "Frais"
          ) {
            this.accountsList.push(element);
          } else if (
            element.libelle == "Carte Importateur" ||
            element.libelle == "Frais"
          ) {
            this.comptesReglement.push(element);
          }
        });
      });
    },
    
    async caisseData() {
      await this.$http.get("/caisse-principales").then((response) => {
        this.operations = response.data.data;
        this.operationsBackup = response.data.data;
        this.operationsNumber = this.operations.length
        this.allRows = this.operations.length

        this.operations.sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          });



      });
    },

    addCompte(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.addCompteForm.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.addCompteFormulaire.type = "caisse";
          this.addCompteFormulaire.devise = this.firstCurrency.id;

          this.addCompteFormulaire.solde = this.addCompteFormulaire.solde
            ? this.$thousandFormater(this.addCompteFormulaire.solde)
            : "0";

          if (this.addCompteFormulaire.solde > this.maxSolde) {
            this.showToast(
              "Le montant du solde initial ne doit pas dépasser " +
                this.$thousandSeparator(this.maxSolde) +
                ".",
              "danger"
            );
            this.addCompteFormulaire.solde = 0;
          }

          this.$http
            .post("/comptes", this.addCompteFormulaire)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.modalAddCompte = false;
                // this.comptes.push(result.data.data);
                this.getComptes();
                this.showToast("Compte ajouté avec succès", "success");
              } else {
                this.etatButton = true;
                this.showToast(result.message, "error");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              console.log(e.response.data.message);
              var err = e.response.data.message;
              if (err.libelle) {
                this.errorsLibelleModal = err.libelle[0];
              }
              console.log("le cach");
            });
        }
      });
    },
    compteModal() {
      this.modalAddCompte = true;
    },
    resetModal() {
      this.addCompteFormulaire.libelle = "";
      this.resetInputModal();
    },
    resetInputModal() {
      this.resetInputLibelleModal();
    },
    resetInputLibelleModal() {
      if (this.errorsLibelleModal) {
        this.errorsLibelleModal = null;
      }
    },

    showReport(action) {
      const filters = {
        compte: (compte) =>
          this.comptesFilterValue
            ? compte.libelle === this.comptesFilterValue
            : true,
        caisse: (caisse) =>
          this.caisseFilterValue ? caisse === this.caisseFilterValue : true,
        created_at: (created_at) => {
          let start = this.convert(this.startDateFilterValue);
          let end = this.convert(this.endDateFilterValue);
          created_at = this.toDate(created_at);
          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return created_at >= start;
          } else if (!start && end) {
            return created_at <= end;
          }
          created_at = new Date(created_at);
          start = new Date(start);
          end = new Date(end);
          return created_at >= start && created_at <= end;
        },
      };
      let result = this.globalFilter(this.operations, filters);
      if (action == "print") {
        this.print(result);
      } else {
        return result;
      }
    },

    globalFilter(array, filters) {
      if (!array) array = [];
      array.forEach((element) => {
        element["created_at"] = this.convertg(element["created_at"]);
      });
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // je valide tous les critères de tri
        return filterKeys.every((key) => {
          // ignorer les predicats qui ne sont pas des fonctions
          if (typeof filters[key] !== "function") return true;
          return filters[key](item[key]);
        });
      });
    },

    convertg(date) {
      if (date) {
        date = date.replace(/\//g, "-");
      }
      return date;
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },
    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },
    soldeDebut(data) {
      let start = this.convert(this.startDateFilterValue);
      if (!start) return 0;
      else {
        if (data[data.length]) {
          return data[data.length].solde;
        }
        return 0;
      }
    },

    print(data) {
      let toPrint = {
        type: "Banque",
        concerne: this.comptesFilterValue ? this.comptesFilterValue : "Banque",
        headers: this.headers,
        showSoldeColumn: true,
        cahier: data,
        periodeDu: this.convert(this.startDateFilterValue),
        periodeAu: this.convert(this.endDateFilterValue),
        solde: this.$thousandSeparator(this.soldeCompte),
        soldeDebut: this.$thousandSeparator(this.soldeDebut(data)),
      };

      // console.log(toPrint)

      this.$router.push({
        name: "operation-print",
        params: { data: toPrint },
      });
    },

    showVirement() {
      // window.sessionStorage.setItem("manifeste_id", item.id);

      this.$router.push({
        name: "banque-virement",
      });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      //let data = []
      let obj = {};
      this.ExcelBanque.forEach((element) => {
        obj = {
          "Date de l'operation": element.created_at,
          libelle: element.phone,
          "Compte concerné": element.phone,
          Entrée: element.phone,
          Sortie: element.phone,
          Solde: element.phone,
          Type: element.phone,
          Compte: element.phone,
          FONCTION: element.work,
        };
        //data.push(obj)
      });
      let data = this.showReport("export");

      console.log(data);

      if (data) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Banques",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log("e");

          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      //let data = []
      let obj = {};
      this.comptes.forEach((element) => {
        obj = {
          "Date de l'operation": element.created_at,
          libelle: element.phone,
          "Compte concerné": element.phone,
          Entrée: element.phone,
          Sortie: element.phone,
          Solde: element.phone,
          Type: element.phone,
          Compte: element.phone,
          FONCTION: element.work,
        };
        //data.push(obj)
      });
      let data = this.showReport("export");

      console.log(data);

      if (data) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Banques",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log("e");

          console.log(e);
        }
      }
    },


  },
};
</script>

<style lang="scss" >
.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";
</style>